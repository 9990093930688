import styles from './CompanyDecline.module.css';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import ApproveAD from './ApproveAD';
import { renderCenteredAlert } from '../CenteredAlert';
import { useRecoilState } from 'recoil';
import { companyInfoState, userInfoState } from '../../state';
import useApiUtils from '../../useApiUtils';
import back from '../../icons/back.svg';

const CompanyDecline = (props) => {
  const [companyInfo, setCompanyInfo] = useRecoilState(companyInfoState);
  const [userInfo] = useRecoilState(userInfoState);
  const [checked, setChecked] = useState(false);
  const [delay, setDelay] = useState(2);
  const [subject, setSubject] = useState('Default Subject');
  const [emailContent, setEmailContent] = useState('Default Content');
  const [showApproveAD, setShowApproveAD] = useState(false);
  const [saveButtonState, setSaveButtonState] = useState('normal');

  const { CompanyAutoDecline } = useApiUtils();
  const navigate = useNavigate();

  useEffect(() => {
    if (!companyInfo || !companyInfo.company_rejection_template) return;
    setSubject(companyInfo.company_rejection_template.company_subject);
    setEmailContent(companyInfo.company_rejection_template.company_content);
    setChecked(
      companyInfo.company_rejection_template.company_send_auto_decline_emails
    );
    setDelay(
      companyInfo.company_rejection_template
        .company_auto_decline_email_delay_days
    );
  }, [companyInfo]);

  const handleSaveChanges = async () => {
    if (userInfo.role === 'lvl1') {
      renderCenteredAlert('You are not authorized to perform this action');
      return;
    }
    if (!emailContent.includes('{{Author name}}')) {
      renderCenteredAlert(
        'You cannot remove the placeholders "{{Author name}}"'
      );
      setEmailContent(companyInfo.company_rejection_template.company_content);
      return;
    }

    if (
      companyInfo?.sn_author_invitation &&
      companyInfo?.author_invitation_type === 0 &&
      !emailContent.includes('{{sn_author_invitation_text}}')
    ) {
      renderCenteredAlert(
        'You cannot remove the placeholders "{{sn_author_invitation_text}}"'
      );
      setEmailContent(companyInfo.company_rejection_template.company_content);
      return;
    }

    setSaveButtonState('loading');
    const requestBody = {
      company_content: emailContent,
      company_subject: subject,
      company_send_auto_decline_emails: checked,
      company_auto_decline_email_delay_days: delay,
    };
    try {
      await CompanyAutoDecline(requestBody);
      setSaveButtonState('normal');
      props.setAfterSave(true);
    } catch (error) {
      setSaveButtonState('failure');
    }
  };

  const getButtonText = () => {
    switch (saveButtonState) {
      case 'saving':
        return 'Saving...';
      case 'success':
        return 'Saved Successfully';
      case 'failure':
        return 'Failed to Save';
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;
      default:
        return props.buttonTextNormal;
    }
  };

  const handleBack = () => {
    props.pressBackButton();
  };

  const handleChange = (event) => {
    if (!event.target.checked) {
      setChecked(event.target.checked);
    } else {
      setShowApproveAD(true);
    }
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#1CEAA0',
      '&:hover': {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#a1e0c9',
    },
  }));

  return (
    <div className={styles.logosignincontainer}>
      <div className={styles.signcontainer}>
        {showApproveAD && (
          <ApproveAD return={setShowApproveAD} handleApprove={setChecked} />
        )}
        <div className={styles.body}>
          <div className={styles.titlelogo}>
            <div className={styles.loginparttitle}>
              Edit Auto-Decline Template
            </div>
          </div>

          <div className={styles.loginpartsubtitle1}>
            Edit company auto-decline standard email rejection mail below:
          </div>
          <div className={styles.labelInputContainer}>
            <label className={styles.label}>Subject</label>
            <input
              className={styles.input}
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className={styles.labelInputContainer}>
            <textarea
              className={`${styles.input} ${styles.textarea}`}
              value={emailContent}
              onChange={(e) => setEmailContent(e.target.value)}
              placeholder={emailContent}
            />
          </div>

          <div className={styles.label_enable_auto_delay}>
            <div className={styles.label_enable_auto}>
              Declined emails for auto-declined submissions will be sent after a
              delay of 2 weeks.
            </div>
            {/* <input
              className={styles.inputnumber}
              type="number"
              value={delay}
              onChange={(e) => setDelay(e.target.value)}
            /> */}
          </div>
          <div className={styles.enable_auto}>
            <div className={styles.label_enable_auto}>
              Enable sending decline mails for auto-declined submissions
            </div>
            <PinkSwitch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        </div>
        <div className={styles.fotter}>
          {props.showBackButton && (
            <div className={styles.pagenumber}>
              <div className={styles.pagenumberfirst}>5</div>/
              {props.superadmin ? '6' : '4'}
            </div>
          )}
          <div className={styles.buttoncontainer}>
            {props.showBackButton && (
              <div className={styles.buttoncontainerback}>
                <img src={back} className={styles.back} />
                <div
                  className={styles.buttonBack}
                  onClick={handleBack}
                  data-testid="back"
                >
                  <div className={styles.textbuttonBack}>Back</div>
                </div>
              </div>
            )}
            <div
              className={`${styles.button} ${
                saveButtonState === 'success'
                  ? styles.successButton
                  : saveButtonState === 'failure'
                  ? styles.failureButton
                  : ''
              }`}
              onClick={handleSaveChanges}
            >
              <div className={styles.textbutton}>{getButtonText()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompanyDecline;
